import { Page, Text, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { inchesTo } from "./../LabelHelper";
import UL_INC_LOGO from "./../ULLogos/E83968/Mark_E83968_EN.png";
import UL_LED_LOGO from "./../ULLogos/E330945/Mark_E330945_EN.png";
import React from "react";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  bold: {
    // backgroundColor: '#E4E4E4',
    // "font-weight": 900
  },
});

const LSIFixture = ({ labelDetails }) => {
  const ULDatabase = {
    // 'cfl':{logo:UL_CFL_LOGO},
    // 'hid':{logo:UL_HID_LOGO},
    inc: { logo: UL_INC_LOGO },
    led: { logo: UL_LED_LOGO },
  };
  const UL_Logo_To_Use =
    labelDetails.fixture.lampType &&
    ULDatabase[labelDetails.fixture.lampType].logo;

  const ratingLocation = {
    damp: "Damp",
    wet: "Wet",
    dry: "Dry",
  }[labelDetails.fixture.ratingLocation];

  return (
    <Document rotate={90}>
      {/* https://www.labelsdirect.com/3-x-15-thermal-transfer-industrial-printer-labels-2-mil-polyester-3-core-8-od-gloss-white-4-rollscase */}

      <Page size={[inchesTo(3), inchesTo(1.5)]}>
        <Text
          style={{
            position: "absolute",
            top: "6pt",
            left: "5pt",
            // margin: 5,
            fontSize: "14pt",
            "overflow-wrap": "normal",
          }}
        >
          LSI Industries - Abolite
        </Text>
        <Text
          style={{
            position: "absolute",
            top: "20pt",
            left: "5pt",
            // margin: 5,
            fontSize: "8pt",
            "overflow-wrap": "noral",
          }}
        >
          <b style={styles.bold}>PN: </b>
          {labelDetails.fixture.partNumber}
        </Text>
        {/*<Text style={{*/}
        {/*    position: 'absolute',*/}
        {/*    top: '10pt',*/}
        {/*    left: '140pt',*/}
        {/*    // margin: 5,*/}
        {/*    "fontSize": '8pt',*/}
        {/*    "overflow-wrap":'noral',*/}
        {/*}}><b style={styles.bold}>Built:</b>{moment().format('M/D/yyyy')}</Text>*/}
        <Text
          style={{
            position: "absolute",
            top: "27pt",
            left: "5pt",
            // margin: 5,
            fontSize: "8pt",
            "overflow-wrap": "normal",
          }}
        >
          <b style={styles.bold}>DVO:</b>
          {labelDetails.fixture.orderNumber} / <b style={styles.bold}>Built:</b>
          {moment().format("M/D/yyyy")}
        </Text>
        <Text
          style={{
            margin: 1,
            fontSize: "7pt",
            top: "35pt",
            left: "5pt",
            width: "150pt",
            position: "absolute",
          }}
        >
          MIN 60°C Supply conductors, Suitable for {ratingLocation} Locations,
          RISK OF FIRE, MAX {labelDetails.fixture.maxWattage}W, 120-277VThis
          product must be installed in accordance with applicable installation
          code by a person familiar with the construction and operation of the
          product and hazards involved. DOWN LIGHT ONLY! Country of Origin-USA /
          Conforms to UL STD 1598
        </Text>
        {/*<Text>{`${Date.now()/100000}`}</Text>*/}
        {labelDetails.fixture.UL_Listed && (
          <Image
            src={UL_Logo_To_Use}
            fixed={true}
            style={{
              position: "absolute",
              width: `${0.6}in`,
              left: `${167}pt`,
              top: `${28}pt`,
              // height: '100vh',
              // width: '100vw',
            }}
          />
        )}
      </Page>
    </Document>
  );
};

export default LSIFixture;
